import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AuthService } from "./AuthService";

export const LoginCustomer = createAsyncThunk(
  "ls/LoginCustomer",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().Login(payload);
      console.log({ response });

      if (response?.data?.data?.userType !== "SuperDealer") {
        toast.error("Please Use the Mobile Application");
      } else {
        localStorage.setItem(
          "user_profile",
          JSON.stringify(response?.data?.data, null, 2)
        );
        localStorage.setItem(
          "onboardingStatus",
          JSON.stringify(response?.data?.data?.onboardingStatus, null, 2)
        );
        setTimeout(() => {
          return window.location.replace("/user");
        }, 1);
        toast.success(response?.message);
      }

      return response?.data;
    } catch (error) {
      console.log("error", error?.response);
      toast.error(error?.response?.data?.message || "An error occured");
      return error?.data;
    }
  }
);

export const ResetCustomerPassword = createAsyncThunk(
  "ls/LoginCustomer",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().ResetPassword(payload);
      toast.success(response?.message);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const CurrentUser = createAsyncThunk(
  "ls/CurrentUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().CurrentUser(payload);
      localStorage.setItem(
        "user",
        JSON.stringify(response?.data?.responseModel)
      );
      toast.success(response?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.message);
      return error?.response;
    }
  }
);

export const ServiceReportToday = createAsyncThunk(
  "ls/ServiceReportToday",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().ServiceToday(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.message);
      return error?.response;
    }
  }
);

export const ServiceReportWeek = createAsyncThunk(
  "ls/ServiceReportWeek",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().ServiceWeek(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.message);
      return error?.response;
    }
  }
);

export const AgentDetails = createAsyncThunk(
  "ls/CurrentUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().AgentDetails(payload);
      toast.success(response?.message);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// CustomerStates
export const CustomerStates = createAsyncThunk(
  "ls/CustomerStates",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().CustomerStates(payload);
      toast.success(response?.message);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// SuperdealerWalletSetup
export const SuperdealerWalletSetup = createAsyncThunk(
  "ls/SuperdealerWalletSetup",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().SuperdealerWalletSetup(payload);
      // toast.success(response?.message);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// CustomerLga
export const CustomerLga = createAsyncThunk(
  "ls/CustomerLga",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthService.auth().CustomerLga(payload);
      toast.success(response?.message);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// // CreatePin
// export const CreatePin = createAsyncThunk(
//   "ls/CreatePin",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       const response = await AuthService.auth().CreatePin(payload);
//       // toast.success(response?.message);
//       return response?.data;
//     } catch (error) {
//       //  exitReponse(error);
//       return error?.response;
//     }
//   }
// );


export const AuthSlice = createSlice({
  name: "Auth",
  initialState: "",
  extraReducers: {
    [AgentDetails.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentDetails.fulfilled]: (state, action) => {
      return { ...state, AgentDetails: action.payload, loading: false };
    },
    [AgentDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [LoginCustomer.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [LoginCustomer.fulfilled]: (state, action) => {
      return { ...state, login: action.payload, loading: false };
    },
    [LoginCustomer.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CurrentUser.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CurrentUser.fulfilled]: (state, action) => {
      return { ...state, user: action.payload, loading: false };
    },
    [CurrentUser.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ServiceReportToday.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ServiceReportToday.fulfilled]: (state, action) => {
      return { ...state, service_report_today: action.payload, loading: false };
    },
    [ServiceReportToday.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ServiceReportWeek.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ServiceReportWeek.fulfilled]: (state, action) => {
      return { ...state, service_report_week: action.payload, loading: false };
    },
    [ServiceReportWeek.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // [AgentDetails.pending]: (state) => {
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // },
    // [AgentDetails.fulfilled]: (state, action) => {
    //   return { ...state, deatails: action.payload, loading: false };
    // },
    // [AgentDetails.rejected]: (state, action) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    // },

    [CustomerStates.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CustomerStates.fulfilled]: (state, action) => {
      return { ...state, customer_states: action.payload, loading: false };
    },
    [CustomerStates.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CustomerLga.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CustomerLga.fulfilled]: (state, action) => {
      return { ...state, customer_lga: action.payload, loading: false };
    },
    [CustomerLga.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    
  },
});

export default AuthSlice.reducer;
