import axios from "axios";
import api from "../api";

const authApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const authApi2 = axios.create({
  baseURL: process.env.REACT_APP_ONBOARDING_BACKEND_URL,
});

const AuthModule = () => {
  return {
    Login: function (data) {
      return authApi.post("Auth/login", data);
    },

    ResetPassword: function (data) {
      // return authApi.post("identity/Agent/setnewpassword", data);
      return authApi.post("SecurityService/reset-password", data);
    },

    CurrentUser: function (data) {
      return api.get("identity/Agent/sessiondetails");
    },

    ServiceToday: function (data) {
      return api.get(`wallet/Wallet/report/service/today`);
    },

    ServiceWeek: function (data) {
      return api.get("wallet/Wallet/report/service/this_week");
    },

    AgentDetails: function (data) {
      const { page, limit } = data;
      return api.get(
        `/transactions/report/superdealer/performance/top-resselers?customer_id=501&service_codes=100,101,102,103,106,105&per_page=${limit}&page=${page}`
      );
    },

    // Customer/states
    CustomerStates: function (data) {
      return api.get("customer/states");
    },

    // Customer/lga/1
    CustomerLga: function (data) {
      return api.get(`customer/lga/${data}`);
    },

    // Superdealer/wallet-setup
    SuperdealerWalletSetup: function (data) {
      return api.post("superdealer/wallet-setup", data);
    },

    // // SecurityService/create-pin
    // CreatePin: function (data) {
    //   return authApi.post("SecurityService/create-pin", data);
    // },
  };
};

export const AuthService = {
  auth: AuthModule,
};
